<template>
  <div>
    <Modal
      v-model="confModel"
      width="60%"
    >
      <p
        slot="header"
        class="text-center"
      >{{ info.name }} 站点配置</p>
      <div>
        <div class="m-b-10">
          <RadioGroup
            v-model="assetId"
            type="button"
            size="small"
            button-style="solid"
            @on-change="onChangeAsset"
          >
            <Badge
              v-for="item in assetArray"
              :count="item.count"
              :key="'asset_'+item.id"
            >
              <Radio :label="item.id">{{ item.name }}</Radio>
            </Badge>
          </RadioGroup>
        </div>

        <div>
          <div class="m-b-10" v-show="stationArray.length">
            <Button
              type="primary"
              size="small"
              @click="onSelecteAll"
            >{{isSelectAll? '全选':'取消全选' }}</Button>
          </div>
          <CheckboxGroup v-model="selectedStationIds">
            <Checkbox
              v-for="item in stationArray"
              :key="'st_'+item.id"
              :label="item.id"
              class="m-b-5"
              border
            >{{ item.name }}</Checkbox>
          </CheckboxGroup>
        </div>
      </div>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="confModel = false"
        >取消</Button>
        <Button
          type="primary"
          @click="onConfirm"
        >确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { indexMixins } from '../mixins'
import { getPublisherAssetList } from '@/api/rim/asset'
import { getStationByAssetId } from '@/api/rim/station'
import { setUserStations } from '@/api/msp/workdayUser'
export default {
  mixins: [indexMixins],
  data () {
    return {
      confModel: false,
      info: {},
      publisherId: this.$store.getters.token.userInfo.publisherId,
      assetId: undefined,
      assetArray: [],
      stationArray: [],
      selectedStationIds: []
    }
  },
  computed: {
    isSelectAll () {
      if (this.selectedStationIds.length === 0) {
        // 没有任何选中数据的情况下
        return true
      } else {
        const curStationIds = this.stationArray.map(x => x.id)
        if (curStationIds.every(x => this.selectedStationIds.includes(x))) {
          return false
        } else {
          return true
        }
      }
    }
  },
  methods: {
    showModal (data = null) {
      if (!data) {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
        return false
      }
      this.selectedStationIds = []
      this.getAssetData()
      this.getUserDetail(data.id)
      this.confModel = true
    },
    async getUserDetail (userId) {
      const result = await this.getUserInfo(userId)
      if (result && !result.errcode) {
        this.info = result
        this.selectedStationIds = result.userStationList && result.userStationList.length ? result.userStationList.map(x => x.stationId) : []
      }
    },
    async getAssetData () {
      const result = await getPublisherAssetList({ publisherId: this.publisherId })
      if (result && !result.errcode) {
        this.assetArray = result

        this.assetId = this.assetArray[0].id
        this.getStationData(this.assetId)
      }
    },
    async getStationData (assetId) {
      if (!assetId) {
        this.stationArray = []
        return
      }
      const result = await getStationByAssetId({ assetId })
      if (result && !result.errcode) {
        this.stationArray = result
      }
    },
    onChangeAsset () {
      this.getStationData(this.assetId)
    },
    // 当前选择线路下，所有站点的全选或取消
    onSelecteAll () {
      if (this.isSelectAll) {
        this.stationArray.forEach(item => {
          if (!this.selectedStationIds.some(x => x === item.id)) {
            this.selectedStationIds.push(item.id)
          }
        })
      } else {
        // 取消全选
        this.stationArray.forEach(item => {
          const itemIndex = this.selectedStationIds.findIndex(x => x === item.id)
          if (itemIndex >= 0) {
            this.selectedStationIds.splice(itemIndex, 1)
          }
        })
      }
    },
    onConfirm () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误，并提交？',
        onOk: async () => {
          const postData = {
            userId: this.info.id,
            stationIds: JSON.stringify(this.selectedStationIds)
          }
          const result = await setUserStations(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedStationIds = []
            this.$emit('refreshTable')
            this.confModel = false
          }
        }
      })
    }
  }
  // watch: {
  //   selectedStationIds: {
  //     deep: true,
  //     immediate: true,
  //     handler (val, oldVal) {
  //       console.log(this.assetArray)
  //       const asset = this.assetArray.find(x => x.id === this.assetId)
  //       if (asset) {
  //         asset.count = val && val.length ? val.filter(x => this.stationArray.some(s => s.id === x)).length : 0
  //       }
  //     }
  //   }
  // }
}
</script>
